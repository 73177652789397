const React = require("react");
const ThemeProvider = require("@matix/theme").ThemeProvider;
const ScrollProvider = require("@matix/theme").ScrollProvider;
require("./src/assets/css/template.scss");

exports.wrapRootElement = ({ element }) => (
   <ThemeProvider>
      <ScrollProvider>{element}</ScrollProvider>
   </ThemeProvider>
);
