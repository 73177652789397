import React, { useState, useMemo } from "react";

export const ThemeContext = React.createContext({ theme: "light", setNavbarTheme: () => {} }); //need the shape for gatsby build

export const ThemeProvider = props => {
   const [theme, setNavbarTheme] = useState("light");

   const value = useMemo(() => ({ theme, setNavbarTheme }), [theme]);

   return <ThemeContext.Provider value={value}>{props.children}</ThemeContext.Provider>;
};
