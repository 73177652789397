module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Matix inc","short_name":"Matix","start_url":"https://matix.ca","display":"standalone","cache_busting_mode":"none","background_color":"#0072a8","theme_color":"#0072a8","description":"Travaillez en collaboration avec Matix","scope":"/","lang":"fr","icon":"static/icon-48x48.png","icons":[{"src":"static/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"static/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"static/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"static/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"static/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"static/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"static/icon-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"screenshots":[{"src":"/screenshot.png","type":"image/png","sizes":"540x720"}],"include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-73071907-9","head":false,"defer":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
